@import 'shared-web/styles/variables';

.radio_group_container {
  margin-bottom: $m;
}

.radio_button {
  position: absolute;
  z-index: -10;

  & + label {
    cursor: pointer;
    background-color: $mid_grey;
    padding: $m $l $m $l;
    border-radius: $xxl;
  }

  &:checked + label {
    background-color: $primary;
  }
}

.container {
  display: flex;
  align-items: flex-start;

  label:nth-of-type(1) {
    margin-right: $s;
    margin-bottom: $xs;
  }

  label:nth-of-type(2) {
    margin-bottom: $xs;
  }
}

.label {
  margin-bottom: $s;
}

.error {
  color: $alert;
  min-height: $m;
  user-select: none;
}
