@import 'shared-web/styles/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hands {
  background-image: linear-gradient(
      to bottom,
      rgb(83 85 160 / 0%) 1%,
      $dark_primary 72%
    ),
    url('./repeatable_hands.png');
  height: 225px;
  width: 100vw;
  background-repeat: repeat-x;
  background-size: 460px 356px;
}

.logo {
  height: 68px;
  width: 220px;
  margin-top: $l;
}
