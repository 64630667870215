@import 'shared-web/styles/variables';

.container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: $white;
  overflow-y: auto;
  padding-bottom: 80px;
}

.wrapper {
  max-width: 430px;
  flex: 1;
  padding: $xl $l $s $l;
}

.title_bar {
  display: flex;
  align-items: center;
  margin-bottom: $xs;
  justify-content: space-between;
}

.language_selector {
  display: flex;
  align-items: center;
  margin-bottom: $xs;
  justify-content: flex-end;
}

.display_name {
  display: block;
  margin: 0 $l 0 0;
  color: $primary;
  white-space: nowrap;
  overflow: hidden;
}

.truncation_wrapper {
  display: flex;
}

.truncated_text {
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
