@import 'shared-web/styles/variables';

.modal_container {
  /* https://github.com/get-momo/code/pull/767 */
  display: flex !important;
  align-items: center;
}

.modal {
  background-color: $white;
  border-radius: 4px;
  max-width: 327px;
  box-shadow: rgba(0, 0, 0, 20%) 0 0 40px;
}

.document {
  cursor: pointer;
  height: 239px;
  width: 100%;
  background-image: url('./document.png');
  background-position: center;
  background-size: cover;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 $l $xl;
}

.first_list_item {
  display: flex;
  margin-top: $l;
  align-items: center;
}

.list_item {
  display: flex;
  margin-top: $s;
  align-items: center;
}

.icon {
  height: 18px;
  width: 18px;
  margin-right: $xs;
}

.upload_button {
  cursor: pointer;
  min-height: $xxl;
  border-radius: 4px;
  margin: $l 0 $s;
  background-color: $primary;
  align-items: center;
  justify-content: center;
}
