@import 'shared-web/styles/variables';

.container {
  flex: 1;
  align-self: stretch;
  margin-bottom: $m;
}

.label {
  margin-bottom: $s;
}

.error {
  color: $alert;
  min-height: $m;
  margin-top: $xs;
  user-select: none;
}
