@import 'shared-web/styles/variables';

.address {
  margin: $s 0 $l;
}

.row {
  display: flex;
  flex: 1;
}

.street_name {
  margin-right: $s;
  flex: 2;
}

.postal_code {
  margin-right: $s;
  flex: 1;
}

.region {
  flex: 2;
}

.country_container {
  flex: 1;
  align-self: stretch;
  margin-bottom: $xxl;
}

.label {
  margin-bottom: $s;
}

.country_select {
  cursor: pointer;
  border: none;
  appearance: none;
  height: 48px;
  background-color: $super_light_grey;
  border-radius: 6px;
  padding: $s $m $s $m;
  background-image: url('./chevron_down.png');
  background-size: $l $l;
  background-repeat: no-repeat;
  background-position: right $m top 50%;
  color: rgb(105 105 105);
}
