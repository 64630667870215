@import 'shared-web/styles/variables';

.promotion_container {
  background: url('./discount_tile.jpg');
  background-size: cover;
  margin-top: $m;
  margin-bottom: -$s;
  border-radius: 4px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $m $l $m $m;
  }

  .title {
    letter-spacing: 1px;
  }

  .icon {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: $xs;
    height: 24px;
    width: 24px;
  }
}
