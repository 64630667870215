@import 'shared-web/styles/variables';

.radio_group {
  margin: $xl 0 $xl 0;
}

.submit_button {
  width: 100%;
}

.error {
  color: $alert;
  min-height: $m;
  margin-top: $xs;
  user-select: none;
  margin-left: $s;
}
