@import 'shared-web/styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.case_yes {
  margin-bottom: $xl;
}

.case_no {
  margin: $l 0;
}
