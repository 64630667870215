@import 'shared-web/styles/variables';

.summary_section {
  margin: 0;
  margin-top: $xl;

  .summary_details_section_entry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $xs;
    margin: 0;
    padding: $s 0;
    border-bottom: solid 1px $lighter_grey;
  }

  .summary_address_section_body {
    margin: 0;
    padding: $s 0;
  }
}

.promotion_card {
  margin: $s 0 $xxl;
}

.summary_details {
  margin-bottom: $xl;
}

.footnote {
  margin-top: $l;
}
