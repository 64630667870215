@import 'shared-web/styles/variables';

.wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: $m;
  margin-top: $xl;
}

.link_button {
  white-space: normal;
}
