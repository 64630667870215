@import 'shared-web/styles/variables';

.container {
  margin-bottom: $l;
}

.upload_button {
  display: flex;
  cursor: pointer;
  min-height: $xxl;
  border-width: 0;
  border-radius: 4px;
  padding: 0 $m 0 $m;
  background-color: $primary;
  align-items: center;
  white-space: nowrap;
  width: 100%;
}

.text_wrapper {
  flex: 1;
  align-self: center;
}

.label {
  margin: $s 0;
}

.error {
  color: $alert;
  min-height: $m;
  margin-top: $xs;
  user-select: none;
}

.thumbnails_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: $s;
}
