@import 'shared-web/styles/variables';

.thumbnail_container {
  display: flex;
  border-radius: 2px;
  justify-content: center;
  align-self: center;
  border: 1px solid $silver;
  margin-right: $xs;
  width: $xxl;
  height: $xxl;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: $xxs;
}

.blur_image {
  filter: blur(1px);
}

.spinner_wrapper {
  position: absolute;
  padding: $s;

  .spinner {
    height: $l;
    width: $l;
  }

  .spinner_color {
    stroke: $dark_primary;
  }
}
