@import 'shared-web/styles/variables';

.row {
  display: flex;
  flex: 1;
  gap: $s;
}

.grow_1 {
  flex-grow: 1;
  min-width: 85px;
}

.grow_3 {
  flex-grow: 3;
}

.caption {
  margin-bottom: $s;
}

.margin_bottom {
  margin-bottom: $m;
}
