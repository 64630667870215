@import 'shared-web/styles/variables';

.button {
  all: unset;
  display: flex;
}

.text {
  margin-left: $xs;
}
