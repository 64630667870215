@import 'shared-web/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: $white;
  overflow-y: auto;
}

.content {
  max-width: 430px;
  padding: $l $l $xxl2 $l;
}

.language_selector {
  display: flex;
  align-items: center;
  margin-bottom: $xs;
  justify-content: flex-end;
}

.welcome {
  margin-bottom: $s;
}

.address {
  margin: $l 0 $xs;
}

.image {
  margin: $xxl 0 $l;
  display: flex;
  justify-content: center;
}

.button_wrapper_address {
  display: flex;
  justify-content: flex-end;
  margin: $xxl 0 $l;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  margin: $xxl 0 $xxl3;
}

.button {
  padding: 0 $xl;
}

.reject_invitation_text {
  margin-top: $xxl;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.legal_container {
  display: flex;
  list-style-type: none;
  justify-content: flex-start;
  margin-top: $xxl3;
  width: 100%;
}

.legal_link {
  text-decoration: none;
}

.legal_separator {
  margin: 0 $xxs;
}
