@import 'shared-web/styles/variables';

.background {
  min-height: 100%;
  background-image: $gradient;
}

.header {
  padding: $s 0 0 $l;
}

.text_background {
  margin: -36px 0 $xl;
  background-color: white;
  padding: 0 $s;
  opacity: 0.8;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section {
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.bolded_text {
  margin: $l 0;
}

.continue_button {
  width: 160px;
  margin-bottom: $l;
}

.email {
  margin-top: $s;
  text-decoration: none;
}
