@import 'shared-web/styles/variables';

.margin_bottom_m {
  margin-bottom: $m;
}

.email_link {
  margin: $s 0 $xxl2 0;
}

.button {
  height: 48px;
  border-radius: $xxs;
  border-width: 0;
  margin-top: $xxl2;
  padding: $s $m $s $m;
  background-color: white;
}

.no_underline {
  text-decoration: none;
}
