@import 'shared-web/styles/variables';
@import '../../lib/constants';

.container {
  width: 100%;
  height: $language_selector_height;
  display: flex;
  justify-content: flex-end;
}

.toggle {
  display: flex;
}

.menu {
  padding: 0;
  min-width: 44px;
  width: 44px;
  background-color: $super_light_grey;
  border-radius: 6px;

  &_top {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 5px 10px;
    height: 34px;
  }

  &_bottom {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 5px 10px;
    height: 34px;
  }
}

.dark_background {
  background-color: $silver;
}
