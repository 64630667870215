@import 'shared-web/styles/variables';

.container {
  margin-bottom: 45px;
}

.income_wrapper {
  margin-bottom: $m;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $l;
}

.add_income_button {
  min-height: 0;
  padding: 0;
  background-color: transparent;
  margin-bottom: $m;

  span {
    color: $primary;
    text-decoration: underline;
  }
}

.border_top {
  border-top: 2px solid $primary;
  margin-bottom: $l;
}

.error {
  color: $alert;
  min-height: $m;
  margin-top: $xs;
  user-select: none;
}
