html {
  overflow-y: scroll;
  overscroll-behavior: none;

  /*
    Prevent page from "jumping"
    it happens when opening a row menu popup on Firefox
  */
  overflow-x: hidden;
}

body {
  font-family: Roboto, sans-serif;
  margin: 0;

  input {
    box-sizing: border-box;
  }
}

p {
  margin: 0;
}

a:hover {
  color: white;
}

a:visited {
  color: white;
}

/*
https://github.com/get-momo/issues/issues/1593
The bootstrap modal adds overflow: hidden to the body.
This causes a rendering problem with the sticky footer.
The following fixes this issue */
.modal-open {
  overflow: inherit !important;
}
