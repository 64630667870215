@import 'shared-web/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin: $xxl2 0 $xxl2 0;
}

.customer_service_block_container {
  max-width: 400px;
  margin: 0 $l;
}

.customer_service_email_container {
  margin-top: $l;
  margin-bottom: $xxl2;
}

.momo_link {
  margin-bottom: $xxl2;
  cursor: pointer;
  text-decoration: none;
}

.legal_container {
  display: flex;
  color: $white;
  list-style-type: none;
  padding-left: 0;
  justify-content: center;
}

.legal_link {
  text-decoration: none;
  padding: 0 $xxs 0 $xxs;
}
