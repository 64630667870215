@import 'shared-web/styles/variables';

.background {
  min-height: 100%;
  background-image: $gradient;
}

.header {
  padding: $s 0 0 $l;
}

.hand {
  margin-bottom: $xl;
}

.text_wrapper {
  margin: -92px 0 0;
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.text_background {
  background-color: white;
  padding: 0 $xs;
  opacity: 0.8;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section {
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.circle {
  margin-top: $xl;
  height: 34px;
}

.title {
  margin: $xs 0 $s;
}

.continue_button {
  width: 160px;
  margin: $xl;
}
