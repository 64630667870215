@import 'shared-web/styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.text {
  margin: $m 0 $l;
}
