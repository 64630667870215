@import 'shared-web/styles/variables';

.infomation_container {
  display: flex;
  align-items: center;
  background: url('./background.png');
  background-size: cover;
  height: 72px;
  margin-bottom: 20px;
  margin-top: $l;
  border-radius: 4px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $s $l $s $m;
  }

  .icon {
    margin-left: $xs;
    height: 24px;
    width: 24px;
  }
}
