@import 'shared-web/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  text-align: center;
  align-items: center;
  background-color: $dark_primary;
}

.content {
  max-width: 400px;
  margin: 0 $l;
}
