@import 'shared-web/styles/variables';

.address {
  margin: $s 0 $l;
}

.row {
  display: flex;
  flex: 1;
}

.manager_street_name {
  margin-right: $s;
  flex: 2;
}

.manager_postal_code {
  margin-right: $s;
  flex: 1;
}

.manager_region {
  flex: 2;
}

.manager_country {
  margin-bottom: $xl;
}

.helper_text {
  margin-top: -$l;
}
