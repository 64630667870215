@import 'shared-web/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
  background-image: $gradient;
  padding: 0 0 $xxl2;
}

.content {
  max-width: 430px;
  width: 100%;
  flex: 1;
  padding: $xl $xl 0;
}

.image {
  margin: $xxl 0 $l;
  display: flex;
  justify-content: center;
}

.thanks_container {
  display: flex;
  justify-content: center;
}

.headline {
  margin-bottom: $l;
  padding: 0 $s 0;
  background-color: $white;
}

.data_received_text {
  margin-bottom: $xxl;
}

.sms_text {
  margin-bottom: $xxl;
}

.reach_us_text {
  margin-bottom: $s;
}
