@import 'shared-web/styles/variables';

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.buttons_wrapper {
  display: flex;
  max-width: 400px;
  flex: 1 1;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 $l;
}

.return_button {
  background-color: $silver;
}

.continue_button {
  padding: 0 $xl;
}

.please_wait {
  display: flex;
  align-items: center;
  margin-bottom: $xxl;
}

.spinner {
  height: 20px;
  width: 20px;
  margin: $xs;
}

.spinner_color {
  stroke: $dark_primary;
}
