@import 'shared-web/styles/variables';

.coowner_button {
  margin-bottom: $xl;
}

.add_second_person_button {
  padding: 0;
  background-color: transparent;

  span {
    color: $primary;
    text-decoration: underline;
  }
}

.header {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex: 1;
  gap: $s;
  margin-bottom: $l;
}

.separator {
  width: 100%;
  margin: $m 0 $l;
  border: solid 1px $primary;
}

.margin_bottom {
  margin-bottom: $m;
}
