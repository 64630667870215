@import 'shared-web/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.content {
  flex: 1;
}
