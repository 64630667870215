@import 'shared-web/styles/variables';

.text {
  margin: 0 $l 0 $l;
}

.button_text {
  text-decoration: none;
}

.button {
  width: 100%;
  margin-top: $xl;
  text-decoration: none;
}
