@import 'shared-web/styles/variables';

.invitation_stepper_wrapper {
  margin-top: $l;
}

.invitation_stepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $xxs;
  padding: $xl $l;
  background-color: $white;
  border-top: 1px solid $lighter_grey;
  user-select: none;
  cursor: default;

  .divider {
    height: 2.5px;
    box-sizing: border-box;
    border-bottom: 1px solid $lighter_grey;
    flex-grow: 2;
  }

  .step {
    .number {
      display: inline-block;
      height: 18px;
      padding: 0 $xxs;
      min-width: 18px;
      text-align: center;
      margin-right: $xxs;
      color: $white;
      background-color: $mid_grey;
      border-radius: 9px;
    }

    &.active {
      .number {
        background-color: $dark_primary;
      }
    }
  }
}
