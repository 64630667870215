@import 'shared-web/styles/variables';

.subtitle {
  margin: $s 0 $l;

  .link {
    color: $dim;
  }
}

.confirm {
  margin-top: $l;

  .link {
    color: $dim;
  }
}

.checkbox {
  align-items: center;

  &:hover,
  &:visited {
    color: $dim;
  }
}

.checkbox_container {
  margin-bottom: $m;
}

.overlay_button {
  background-color: $white;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.select_all {
  width: 100%;
}
