@import 'shared-web/styles/variables';

.address {
  margin: $s 0 $l;
}

.row {
  display: flex;
  flex: 1;
}

.margin_bottom {
  margin-bottom: $m;
}

.property_owner_street_name {
  margin-right: $s;
  flex: 2;
}

.property_owner_postal_code {
  margin-right: $s;
  flex: 1;
}

.property_owner_region {
  flex: 2;
}
